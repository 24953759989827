export const TestimoniaData=[
    {
       heading:"I love the check ups in terms of making sure everything is fine by asking questions for better understanding of the eyes issue.",
       Name:"Ikenna O. Elliot",
       
    },
    {
       heading:"I was completely satisfied with my experience at Imageplus. The Staff and Doctors were not just kind but also professional. Thank you so much for saving my vision, Imageplus",
       Name:"Henry Kalu"
    },
    {
       heading:"I was completely satisfied with my experience at Imageplus. The Staff and Doctors were not just kind but also professional. Thank you so much for saving my vision, Imageplus",
       Name:"Mathew Peter Okon"
    },
]