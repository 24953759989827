import DashboardUsersOption from "../../components/Dashboard/DashboardUsers/DashboardUsers"

 
const DashboardUsers = () => {
  return (
   <DashboardUsersOption/>
  )
}

export default DashboardUsers
