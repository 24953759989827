import React from 'react'
import Newsupdated from '../components/News & updated/News & updated'

const Newsupdates = () => {
  return (
    <>
      <Newsupdated/>
    </>
  )
}

export default Newsupdates
