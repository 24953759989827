import doctor from "../assets/doctors/doctor-1.jpg"
import doctor2 from "../assets/doctors/doctor-2.jpg"
const UserData=[
    {
    img:doctor2,
    Name:"Dr (Lady) Ijeoma Nnabuife",
    email:"newemail@gmail.com"
   },
    {
    img:doctor,
    Name:"Dr Edidiong Monday Udo",
    email:"newemail@gmail.com"
}
]
 
export default UserData