 import DashboardTestimonial2 from "../../components/Dashboard/DashboardTestimonials/DashboardTestimonialsMain"

 
const DashboardTestimoni = () => {
  return (
     <DashboardTestimonial2/>
  )
}

export default DashboardTestimoni
